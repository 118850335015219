@tailwind base;
@tailwind components;
@tailwind utilities;

.animate-marquee {
  animation: marquee 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.bg-grass {
  background-color: #7ac74c;
}
.bg-poison {
  background-color: #a33ea1;
}
.bg-normal {
  background-color: #a8a77a;
}
.bg-fire {
  background-color: #ee8130;
}
.bg-water {
  background-color: #6390f0;
}
.bg-electric {
  background-color: #f7d02c;
}
.bg-ice {
  background-color: #96d9d6;
}
.bg-ground {
  background-color: #e2bf65;
}
.bg-fighting {
  background-color: #c22e28;
}
.bg-flying {
  background-color: #a98ff3;
}
.bg-psychic {
  background-color: #f95587;
}
.bg-bug {
  background-color: #a6b91a;
}
.bg-rock {
  background-color: #b6a136;
}
.bg-ghost {
  background-color: #735797;
}
.bg-dragon {
  background-color: #6f35fc;
}
.bg-dark {
  background-color: #705746;
}
.bg-steel {
  background-color: #b7b7ce;
}
.bg-fairy {
  background-color: #d685ad;
}
